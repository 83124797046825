import { render, staticRenderFns } from "./needHistoryViewer.vue?vue&type=template&id=fa898d12&scoped=true"
import script from "./needHistoryViewer.vue?vue&type=script&lang=js"
export * from "./needHistoryViewer.vue?vue&type=script&lang=js"
import style0 from "@/assets/LAcordion.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./needHistoryViewer.vue?vue&type=style&index=1&id=fa898d12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa898d12",
  null
  
)

export default component.exports